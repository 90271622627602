<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card flat>
      <Async :loading="isFetching">
        <v-card-title class="flex-center">設定</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              rounded
              label="單筆最小金額"
              v-model.number="form.single_min_amt"
              :readonly="isCanEdit"
              type="number"
              outlined
            ></v-text-field>

            <v-text-field
              rounded
              label="單筆最大金額"
              v-model.number="form.single_max_amt"
              :readonly="isCanEdit"
              type="number"
              outlined
            ></v-text-field>

            <v-select
              rounded
              :items="feeModeOptions"
              v-model="fee_mode"
              :readonly="isCanEdit"
              label="手續費計算方式"
              outlined
            ></v-select>
            <v-card
              outlined
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
            >
              <SizeBox height="28" />

              <v-form ref="form" @submit.prevent="submit">
                <v-card-text>
                  <v-text-field
                    rounded
                    v-if="fee_mode === 'fee'"
                    v-model.number="form.fee"
                    :rules="[checkFloat]"
                    :readonly="isCanEdit"
                    label="單筆手續費"
                    type="number"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    rounded
                    v-else-if="fee_mode === 'fee_percent'"
                    v-model.number="form.fee_percent"
                    :rules="[checkFloat]"
                    :readonly="isCanEdit"
                    label="單筆費率（%）"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </v-card>
          </v-container>
        </v-card-text>

        <v-card-actions class="flex-center">
          <v-btn
            rounded
            color="primary"
            depressed
            width="320"
            height="40"
            @click="submit"
            :loading="isLoading"
            :disabled="isCanEdit"
          >
            保存
          </v-btn>
        </v-card-actions>
      </Async>
      <SizeBox height="32" />
    </v-card>
  </div>
</template>

<script>
import { editSystemPayRule, getSystemPayRule } from '@/api/payRules';

export default {
  data() {
    return {
      isLoading: false,
      isFetching: true,
      id: '',
      form: {
        single_min_amt: 0,
        single_max_amt: 0,
        fee_percent: 0,
        fee: 0
      },
      fee_mode: '', // 'fee_percent' or 'fee'
      feeModeOptions: [
        {
          text: '單筆手續費',
          value: 'fee'
        },
        {
          text: '單筆費率',
          value: 'fee_percent'
        }
      ]
    };
  },

  computed: {
    isCanEdit() {
      return !this.$getGrantValue('pay_rule.edit');
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.isFetching = true;
      const Rule = await getSystemPayRule();
      if (!Rule.error) {
        this.id = Rule.id;
        this.fee_mode = Rule.fee_mode;
        Object.keys(this.form).forEach(
          (key) => (this.form[key] = Number(Rule[key]))
        );
      }
      this.isFetching = false;
    },

    checkFloat(v) {
      const float = `${v}`.split('.')[1];

      if (float) {
        return float.length <= 2 ? true : '小數點後至多兩位';
      }

      return true;
    },

    async submit() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      const passData = {
        id: this.id,
        fee_mode: this.fee_mode,
        ...this.form
      };

      if (this.fee_mode === 'fee') {
        delete passData.fee_percent;
      } else if (this.fee_mode === 'fee_percent') {
        delete passData.fee;
      }

      const { success } = await editSystemPayRule(passData);
      if (success) {
        this.$toast('編輯成功');
      } else {
        this.$toast.error('編輯失敗');
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
